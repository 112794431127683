import { modalConstants } from '../constants/modal.constants';
import React from 'react';

export function showModal(component: React.ReactNode) {
    // @ts-ignore
    return dispatch => {
        dispatch(clear());
        dispatch(show(component));
    };

    function clear() { return { type: modalConstants.CLEAR_MODAL }}
    function show(component: React.ReactNode) { return { type: modalConstants.SHOW_MODAL, payload: component }}
}

export function showPopup(component: React.ReactNode) {
    // @ts-ignore
    return dispatch => {
        dispatch(clear());
        dispatch(show(component));
    };

    function clear() { return { type: modalConstants.CLEAR_POPUP }}
    function show(component: React.ReactNode) { return { type: modalConstants.SHOW_POPUP, payload: component }}
}

export const hideModal = () => ({
    type: modalConstants.HIDE_MODAL,
});

export const hidePopup = () => ({
    type: modalConstants.HIDE_POPUP,
});
