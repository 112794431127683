import { applyMiddleware, combineReducers, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';

import { rootReducer } from './reducers/root.reducer';

export const history = createBrowserHistory();

const middlewares = [
    thunk,
    logger,
    routerMiddleware(history),
];

/**
 * Main redux store.
 */
const store = createStore(
    combineReducers({
        router: connectRouter(history),
        ...rootReducer,
    }),
    {},
    applyMiddleware(...middlewares),
);

export default store;
