import { modalConstants } from '../constants/modal.constants';

const initialState = {
    visible: false,
    popupVisible: false,
    component: null,
    popupComponent: null,
};

export const modalReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case modalConstants.HIDE_MODAL:
            return {
                ...state,
                visible: false,
                component: null,
            };
        case modalConstants.SHOW_MODAL:
            return {
                ...state,
                visible: true,
                component: action.payload,
            };
        case modalConstants.CLEAR_MODAL:
            return {
                ...state,
                component: null,
            };

        case modalConstants.HIDE_POPUP:
            return {
                ...state,
                popupVisible: false,
                popupComponent: null,
            };
        case modalConstants.SHOW_POPUP:
            return {
                ...state,
                popupVisible: true,
                popupComponent: action.payload,
            };
        case modalConstants.CLEAR_POPUP:
            return {
                ...state,
                popupComponent: null,
            };
        default:
            return state;
    }
};
