export const headerConstants = {
    SET_MODULE: 'NAV_SET_MODULE',

    SET_NAME: 'HEADER_SET_NAME',
    CLEAR_TABS: 'HEADER_CLEAR_TABS',
    SET_TABS: 'HEADER_SET_TABS',
    ADD_TAB: 'HEADER_ADD_TAB',
    UPDATE_TAB: 'HEADER_UPDATE_TAB',
    SET_ACTIVE_TAB: 'HEADER_SET_ACTIVE_TAB',
    SET_MOBILE_OPEN: 'HEADER_SET_MOBILE_OPEN',
};
