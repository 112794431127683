import * as Sentry from '@sentry/browser';

import { formErrorConstants } from '../constants/formError.constants';

export function setFormErrors(errors) {
    return dispatch => {
        dispatch(request(errors));

        Sentry.captureMessage('Received form erorrs: ' + JSON.stringify(errors));
    };

    function request(errors) { return { type: formErrorConstants.SET_ERRORS, payload: errors }}
}

export const addFormError = formError => ({
    type: formErrorConstants.ADD_ERROR,
    payload: formError,
});

export const removeFormError = formError => ({
    type: formErrorConstants.REMOVE_ERROR,
    payload: formError,
});

export const clearFormErrors = () => ({
    type: formErrorConstants.CLEAR,
});
