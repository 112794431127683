import { authReducer } from './auth.reducer';
// import { navigationReducer } from './navigation.reducer';
import { profileReducer } from './profile.reducer';
import { headerReducer } from './header.reducer';
import { modalReducer } from './modal.reducer';
// import { employeeReducer } from './employee.reducer';
// import { contractsReducer } from './contracts.reducer';
// import { groupReducer } from './group.reducer';
import { formErrorReducer } from './formError.reducer';
// import { snackbar } from './snackbar.reducer';
// import { region } from './region.reducer';
// import { layoutReducer } from './layout.reducer';

export const rootReducer = {
    authReducer,
    //
    // navigationReducer,
    profileReducer,
    headerReducer,
    modalReducer,
    //
    // employeeReducer,
    // contractsReducer,
    // groupReducer,
    formErrorReducer,
    // snackbar,
    // region,
    // layoutReducer,
};
