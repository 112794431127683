import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import './index.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';

// check if production environment
if (process.env.NODE_ENV !== 'development') {
    // load sentry error reporting
    Sentry.init({
        dsn: 'https://8261d86d284a4db784945557baf3d5da@sentry.urban-scope.de/5',
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });

    // serviceWorker.register();
} else {
    console.log('NOTE: Running in development mode.');
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
