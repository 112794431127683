import axios from 'axios';
import { platformIdentifier } from '../index';
import { onError } from '../interceptor';

import config from '../../config.json';

const baseUrl = process.env.NODE_ENV === 'development' ?
    config.api.endpoints.leistungen.dev : config.api.endpoints.leistungen.prod;

const leistungen = axios.create({
    baseURL: baseUrl + '/',
    timeout: 1000000,
    headers: {
        'X-Requested-With': platformIdentifier,
    },
    maxRedirects: 0,
});

// api error handling
leistungen.interceptors.response.use(response => response, err => onError(err));

leistungen.interceptors.request.use(request => request, err => err);

export default leistungen;
