import { headerConstants } from '../constants/header.constants';

const initialState = {
    mobileOpen: false,
};

export const headerReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case headerConstants.SET_MOBILE_OPEN:
            return {
                ...state,
                mobileOpen: Boolean(action.payload),
            };
        default:
            return state;
    }
};
