import * as Sentry from '@sentry/react';
import leistungen from '../rest/clients/leistungen';

export interface Credentials {
    uuid: string,
    sessionToken: string,
    refreshToken?: string,
}

export interface Session {
    id: number,
    // TODO
}

/**
 * Checks login credentials.
 *
 * @param email
 * @param password
 * @returns {Promise<{uuid, token}>}
 */
const login = async (email: string, password: string): Promise<Credentials> => {
    const { data } = await leistungen({
        method: 'post',
        url: 'auth/login',
        data: { email, password },
    });

    if (!data) {
        throw new Error('Ungültige Antwort');
    }

    const { uuid, sessionToken, refreshToken } = data;
    if (!uuid || !sessionToken || !refreshToken) {
        throw new Error('Anmeldung fehlgeschlagen');
    }

    Sentry.configureScope(scope => {
        scope.setUser({ email, uuid });
    });

    return {
        uuid,
        sessionToken,
        refreshToken,
    };
};

const logout = async (loggedIn: boolean = true): Promise<void> => {
    if (loggedIn) {
        try {
            await leistungen.get('auth/logout');
        } catch (ex) {
        }
    }

    Sentry.configureScope(scope => {
        scope.clear();
    })

    // delete all localstorage entries (session, etc.)
    localStorage.clear();
};

const getSessionToken = async (refreshToken: string): Promise<string> => {
    const { data } = await leistungen.post(`auth/refresh`);

    if (!data) {
        throw new Error('Session Token konnte nicht abgerufen werden');
    }

    return data.sessionToken;
};

const resetPassword = async (email: string, captcha: string): Promise<void> => {
    await leistungen({
        method: 'post',
        url: 'auth/reset',
        data: { email },
        headers: { 'Captcha': captcha },
    });
};

const confirmPassword = async (password: string, token: string, email: string): Promise<void> => {
    await leistungen({
        method: 'post',
        url: 'auth/confirmReset',
        data: { password, email },
        headers: { 'Authorization': token },
    });
};

const completeRegistration = async (password: string, token: string, email: string): Promise<void> => {
    await leistungen({
        method: 'post',
        url: 'auth/verify',
        data: { password, email },
        headers: { 'Authorization': token },
    });
};

export const authService = {
    login,
    logout,
    getSessionToken,
    resetPassword,
    confirmPassword,
    completeRegistration,
};
