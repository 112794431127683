import { formErrorConstants } from '../constants/formError.constants';

const initialState = {
    errors: {},
};

export const formErrorReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case formErrorConstants.SET_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case formErrorConstants.ADD_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.payload,
                }
            };
        case formErrorConstants.REMOVE_ERROR:
            // TODO
            return state;
        case formErrorConstants.CLEAR:
            return initialState;
        default:
            return state;
    }
};
