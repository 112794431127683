import { profileConstants } from '../constants/proile.constants';

const initialState = {
    loaded: false,
    permissions: [],
    error: null,
};

export const profileReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case profileConstants.SET_PROFILE:
            return {
                ...state,
                ...action.payload,
                loaded: true,
            }
        default:
            return state;
    }
};
