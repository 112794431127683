import React from 'react';
import moment from 'moment';
import loadable from '@loadable/component';
import { history } from './store';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@material-ui/core/styles';

// themes
import theme from './themes/default';
import auth from './themes/auth';

import leistungen from './rest/clients/leistungen';

import './App.css';
import Typography from '@material-ui/core/Typography';

const Skeleton = loadable(() => import('./components/layout/Skeleton'));
const ModalHandler = loadable(() => import('./components/ModalHandler'));

const LoginScreen = loadable(() => import('./screens/auth/LoginScreen'));
const ResetPasswordScreen = loadable(() => import('./screens/auth/ResetPasswordScreen'));
const VerifyAccountScreen = loadable(() => import('./screens/auth/VerifyAccountScreen'));
const SetNewPasswordScreen = loadable(() => import('./screens/auth/SetNewPasswordScreen'));
const ExternalLoginScreen = loadable(() => import('./screens/auth/ExternalLoginScreen'));
const LogoutScreen = loadable(() => import('./screens/auth/LogoutScreen'));

const DashboardScreen = loadable(() => import('./screens/Dashboard'));
const UsersScreen = loadable(() => import('./screens/Users'));
const ProfileScreen = loadable(() => import('./screens/Profile'));
const LogisticScreen = loadable(() => import('./screens/Logistic'));
const ArticlesScreen = loadable(() => import('./screens/Articles'));
const AddressScreen = loadable(() => import('./screens/Addresses'));

const sessionToken = localStorage.getItem('session.token');
if (sessionToken) {
    // set api access token in rest client (axios)
    leistungen.defaults.headers.common['Authorization'] = 'Bearer ' + sessionToken;
}

moment.locale('de');

function App() {
    const signedIn: boolean = useSelector((store: any) => store.authReducer.signedIn);

    return (
        <Sentry.ErrorBoundary fallback={<Typography>Ein Fehler ist aufgetreten</Typography>} showDialog={true}>
            <ConnectedRouter history={history}>
                <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale="de">
                    <ThemeProvider theme={theme}>
                        <Skeleton>
                            <ModalHandler />

                            {!signedIn ? (
                                <ThemeProvider theme={auth}>
                                    <Switch>
                                        <Route path="/auth/login" component={LoginScreen} />
                                        <Route path="/auth/external/:uuid/:token" component={ExternalLoginScreen} />
                                        <Route path="/auth/verify/:email/:token" component={VerifyAccountScreen} />

                                        <Route exact path="/auth/reset" component={ResetPasswordScreen} />
                                        <Route path="/auth/reset/:email/:token" component={SetNewPasswordScreen} />

                                        <Route path="/logout" component={LogoutScreen} />
                                        <Route>
                                            <Redirect to="/auth/login" />
                                        </Route>
                                    </Switch>
                                </ThemeProvider>
                            ) : (
                                <Switch>
                                    <Route exact path="/" component={DashboardScreen} />
                                    <Route path="/benutzer" component={UsersScreen} />
                                    <Route path="/logout" component={LogoutScreen} />

                                    <Route path="/artikel" component={ArticlesScreen} />
                                    <Route path="/adressen" component={AddressScreen} />
                                    <Route path="/logistik" component={LogisticScreen} />
                                    <Route path="/profil" component={ProfileScreen} />

                                    <Route path="/auth/external/:uuid/:token" component={ExternalLoginScreen} />
                                    <Route>
                                        <Redirect to="/" />
                                    </Route>
                                </Switch>
                            )}
                        </Skeleton>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </ConnectedRouter>
        </Sentry.ErrorBoundary>
    );
}

export default App;
