import { authConstants } from '../constants/auth.constants';

const initialState = {
    loading: false,
    signedIn: localStorage.getItem('session.token') !== null,
    loggingIn: false,
    loggingOut: false,
    error: null,
};

export const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                ...state,
                signedIn: true,
                loggingIn: false,
            };
        case authConstants.LOGIN_FAILURE:
            return {
                ...state,
                loggingIn: false,
            };
        case authConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                signedIn: false,
            };

        case authConstants.RESET_PASSWORD_REQUEST:
        case authConstants.CONFIRM_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case authConstants.RESET_PASSWORD_SUCCESS:
        case authConstants.CONFIRM_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case authConstants.RESET_PASSWORD_FAILURE:
        case authConstants.CONFIRM_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
