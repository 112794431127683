import React from 'react';
import { push } from 'connected-react-router';

import { authConstants } from '../constants/auth.constants';
import { authService } from '../services/auth.service';
import { clearFormErrors, setFormErrors } from './formError.actions';
import { hideModal, showModal } from './modal.actions';
import leistungen from '../rest/clients/leistungen';
import InfoDialog from '../components/dialogs/InfoDialog';

export function login(email, password) {
    return dispatch => {
        dispatch(request());

        authService.login(email, password)
            .then(({ uuid, sessionToken, refreshToken }) => {
                // save session credentials in localstorage
                // TODO: use central storage/api?
                localStorage.setItem('session.uuid', uuid);
                localStorage.setItem('session.token', sessionToken);
                localStorage.setItem('session.refresh_token', refreshToken);
                localStorage.setItem('session.created', `${Date.now()}`);

                // set authorization header
                leistungen.defaults.headers.common['Authorization'] = 'Bearer ' + sessionToken;

                dispatch(success());

                // redirect to root url
                dispatch(push('/'));
            })
            .catch(err => {
                // extract axios response from error object
                const { response } = err;

                // check for invalid credentials
                if (response && response.status === 400) {
                    dispatch(setFormErrors({
                        auth: 'Ungültige Anmeldedaten',
                    }));
                }

                dispatch(failure(err));
            });
    };

    function request() { return { type: authConstants.LOGIN_REQUEST }}
    function success() { return { type: authConstants.LOGIN_SUCCESS }}
    function failure(err) { return { type: authConstants.LOGIN_FAILURE, payload: err }}
}

export function logout(loggedIn = true) {
    return dispatch => {
        dispatch(request());

        authService.logout(loggedIn)
            .then(() => {
                dispatch(success());
                dispatch(hideModal());

                // redirect to root url
                dispatch(push('/'));
            })
            .catch(err => {
                dispatch(hideModal());

                // redirect to root url
                dispatch(push('/'));
            });
    };

    function request() { return { type: authConstants.LOGOUT_REQUEST }}
    function success() { return { type: authConstants.LOGOUT_SUCCESS }}
}

export function resetPassword(email, captcha) {
    return dispatch => {
        dispatch(request());

        authService.resetPassword(email, captcha)
            .then(() => {
                dispatch(success());
                dispatch(push('/'));
            })
            .catch(err => {
                const { response } = err;

                if (response) {
                    // invalid captcha
                    if (response.status === 401) {
                        dispatch(showModal(<InfoDialog title="Ungültige Captcha" message="Die Captcha wurde nicht korrekt ausgefüllt." />));
                    }

                    // form error
                    else if (response.status === 422) {
                        dispatch(setFormErrors(response.data.errors));
                    }
                }

                dispatch(failure(err));
            })
    };

    function request() { return { type: authConstants.RESET_PASSWORD_REQUEST }}
    function success() { return { type: authConstants.RESET_PASSWORD_SUCCESS }}
    function failure(err) { return { type: authConstants.RESET_PASSWORD_FAILURE, payload: err }}
}

export function confirmPassword(password, token, email) {
    return dispatch => {
        dispatch(request());
        dispatch(clearFormErrors());

        authService.confirmPassword(password, token, email)
            .then(() => {
                dispatch(success());
                dispatch(push('/'));
            })
            .catch(err => {
                const { response } = err;

                if (response && response.status === 422) {
                    if (response.data.errors.token) {
                        dispatch(push('/'));
                        return;
                    }

                    dispatch(setFormErrors(response.data.errors));
                }

                dispatch(failure(err));
            });
    };

    function request() { return { type: authConstants.CONFIRM_PASSWORD_REQUEST }}
    function success() { return { type: authConstants.CONFIRM_PASSWORD_SUCCESS }}
    function failure(err) { return { type: authConstants.CONFIRM_PASSWORD_FAILURE, payload: err }}
}

export function completeRegistration(password, token, email) {
    return dispatch => {
        dispatch(request());

        authService.completeRegistration(password, token, email)
            .then(() => {
                dispatch(success());
                dispatch(push('/'));
            })
            .catch(err => {
                const { response } = err;

                if (response && response.status === 422) {
                    if (response.data.errors.token) {
                        dispatch(push('/'));
                        return;
                    }

                    dispatch(setFormErrors(response.data.errors));
                }

                dispatch(failure(err));
            });
    };

    function request() { return { type: authConstants.COMPLETE_REGISTRATION_REQUEST }}
    function success() { return { type: authConstants.COMPLETE_REGISTRATION_SUCCESS }}
    function failure(err) { return { type: authConstants.COMPLETE_REGISTRATION_FAILURE, payload: err }}
}
