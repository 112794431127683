export const authConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    CONFIRM_PASSWORD_REQUEST: 'CONFIRM_PASSWORD_REQUEST',
    CONFIRM_PASSWORD_SUCCESS: 'CONFIRM_PASSWORD_SUCCESS',
    CONFIRM_PASSWORD_FAILURE: 'CONFIRM_PASSWORD_FAILURE',

    COMPLETE_REGISTRATION_REQUEST: 'COMPLETE_REGISTRATION_REQUEST',
    COMPLETE_REGISTRATION_SUCCESS: 'COMPLETE_REGISTRATION_SUCCESS',
    COMPLETE_REGISTRATION_FAILURE: 'COMPLETE_REGISTRATION_FAILURE',
};
