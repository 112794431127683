import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';

interface InfoDialogProps {
    title: string,
    message: string,
    onClose?: Function,
    onNext?: Function,
    severity?: Color,
}

const InfoDialog = ({ title, message, onClose, onNext, severity }: InfoDialogProps) => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);

        if (onClose) {
            onClose();
        }
    };

    const handleNext = () => {
        setOpen(false);

        if (onNext) {
            onNext();
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            aria-labelledby="info-dialog-title"
            aria-describedby="info-dialog-description"
        >
            <DialogTitle id="info-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {message && (
                    <Alert style={{ maxWidth: 600 }} severity={severity}>{message}</Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNext}>
                    Weiter
                </Button>
            </DialogActions>
        </Dialog>
    );
};

InfoDialog.defaultProps = {
    severity: 'info',
};

export default InfoDialog;
